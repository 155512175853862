import {MonoTypeOperatorFunction, Observable, ObservableInput, ObservedValueOf, OperatorFunction, Subject} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {SentryErrorHandler} from '../@core/sentry-error-handler';

export function switchTap<T>(getObservable: (res: T) => Observable<any>): MonoTypeOperatorFunction<T> {
    return input$ => input$.pipe(switchMap(res => getObservable(res).pipe(map(() => res))));
}

export function catchAndPropagateError<T, K extends ObservableInput<any>>(fn: (err: any, replaceObs: Observable<T>) => K): OperatorFunction<T, T | ObservedValueOf<K>> {
    return function (source$): Observable<T | ObservedValueOf<K>> {
        return source$.pipe(
            tap({error: err => SentryErrorHandler.handleError(err)}),
            catchError(fn));
    };
}

export function makeCold<T>(obsFn: () => Subject<T>) {
    return new Observable<T>(observer => {
        const obs$ = obsFn();
        obs$.subscribe(observer);
        return () => obs$.complete();
    });
}
